<template>
  <div id="natcon-individual-awards-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <v-btn
          @click.prevent="refreshPageData"
          color="#a4c639"
          min-width="92"
          rounded
          medium
          class="font-size-h6 px-3 py-3 mr-3 white--text"
          >Refresh
        </v-btn>
        <v-btn
          @click.prevent="openLomDetailsPrompt = true"
          color="#AD1457"
          min-width="92"
          medium
          class="font-size-h6 px-3 py-3 mr-3 white--text"
          >LO Statistics
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container v-if="RoleId == 2">
          <v-row>
            <v-col cols="12" sm="6" lg="4" md="6">
              <h6><span class="text-danger">* </span> Zone</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="ZoneCodeOptions"
                :rules="ZoneCodeRules"
                v-model="ZoneCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="6">
              <h6><span class="text-danger">* </span> Lom</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="LomCodeOptions"
                :rules="LomCodeRules"
                v-model="LomCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row wrap>
            <v-col cols="12" sm="6" md="6" lg="3">
              <v-card color="#577590" style="height: 200px" v-if="CardLoadingFlag">
                <br />
                <v-col cols="12" v-for="n in 3" :key="n">
                  <v-skeleton-loader v-bind="attrs" type="text"></v-skeleton-loader>
                  <br />
                </v-col>
              </v-card>
              <v-card
                color="#577590"
                id="card1"
                style="height: 200px"
                v-if="!CardLoadingFlag"
              >
                <v-card-text id="card-text">
                  <h5>Deadline</h5>
                  <br />
                  <br />
                  <h2>
                    {{ NatconIndividualAwardEndDate }}
                  </h2>
                  <!-- <h2>
                    30-Oct-2023
                    <br />
                    11.59 pm
                  </h2> -->
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="3">
              <v-card color="#4d908e" style="height: 200px" v-if="CardLoadingFlag">
                <br />
                <v-col cols="12" v-for="n in 3" :key="n">
                  <v-skeleton-loader v-bind="attrs" type="text"></v-skeleton-loader>
                  <br />
                </v-col>
              </v-card>
              <v-card
                color="#4d908e"
                id="card1"
                style="height: 200px"
                v-if="!CardLoadingFlag"
              >
                <v-card-text id="card-text">
                  <h5>
                    Total <br />
                    Awards
                  </h5>
                  <br />
                  <h1>{{ TotalNatconIndividualAward }}</h1>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="3">
              <v-card color="#43aa8b" style="height: 200px" v-if="CardLoadingFlag">
                <br />
                <v-col cols="12" v-for="n in 3" :key="n">
                  <v-skeleton-loader v-bind="attrs" type="text"></v-skeleton-loader>
                  <br />
                </v-col>
              </v-card>
              <v-card
                color="#43aa8b"
                id="card1"
                style="height: 200px"
                v-if="!CardLoadingFlag"
              >
                <v-card-text id="card-text">
                  <h5>
                    Total <br />
                    Applied
                  </h5>
                  <br />
                  <h1>{{ TotalApplied }}</h1>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="3">
              <v-card color="#f3722c" style="height: 200px" v-if="CardLoadingFlag">
                <br />
                <v-col cols="12" v-for="n in 3" :key="n">
                  <v-skeleton-loader v-bind="attrs" type="text"></v-skeleton-loader>
                  <br />
                </v-col>
              </v-card>
              <v-card
                color="#f3722c"
                id="card1"
                style="height: 200px"
                v-if="!CardLoadingFlag"
              >
                <v-card-text id="card-text">
                  <h5>Partially Saved</h5>
                  <br />
                  <h1>{{ TotalPartiallySaved }}</h1>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-card>
            <v-tabs
              v-model="tab"
              align-with-title
              next-icon="mdi-arrow-right-bold-box-outline"
              prev-icon="mdi-arrow-left-bold-box-outline"
              show-arrows
              background-color="rgb(244, 236, 236)"
              color="black"
              slider-color="black"
              dark
              centered
              icons-and-text
              style="height: 60px"
            >
              <!-- <v-tabs-slider></v-tabs-slider> -->

              <v-tab href="#tab-1">
                Group I - INDIVIDUAL AWARD
                <!-- <v-icon>mdi-numeric-1-box-multiple-outline</v-icon> -->
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <v-card flat>
                  <v-card-text>
                    <v-container v-if="StartupLoadingFlag">
                      <v-row wrap>
                        <v-col
                          cols="12"
                          xl="8"
                          lg="8"
                          md="12"
                          sm="12"
                          v-for="n in 8"
                          :key="n"
                        >
                          <v-skeleton-loader
                            v-bind="attrs"
                            type="text"
                          ></v-skeleton-loader>
                          <br />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-if="!StartupLoadingFlag">
                      <template>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left text-dark-95">
                                  <h6>Status</h6>
                                </th>
                                <th class="text-left text-dark-95">
                                  <h6>Bid Award</h6>
                                </th>
                                <th class="text-left text-dark-95">
                                  <h6>Award Name</h6>
                                </th>
                                <th class="text-left text-dark-95">
                                  <h6>Actions</h6>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in IndividualAward" :key="item.SNo">
                                <td class="text-left">
                                  <v-chip :color="item.AwardStatusColor" draggable class="font-size-h5 white--text px-4 py-3">
                                    {{ item.AwardStatusTxt }}
                                  </v-chip>
                                </td>
                                <td class="text-left">
                                  <!-- <v-btn
                                    v-if="
                                      tableOptions1.CreateButtonFlag &&
                                      item.AwardStatus == 1
                                    "
                                    color="purple"
                                    class="font-size-h6 white--text"
                                    @click="
                                      addCriteriaDetails(item.AwardTypeId)
                                    "
                                  >
                                    Bid
                                  </v-btn> -->

                                  <v-tooltip
                                    bottom
                                    v-if="
                                      tableOptions1.CreateButtonFlag &&
                                      item.AwardStatus == 1 &&
                                      StartDateFlag &&
                                      EndDateFlag
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="purple"
                                        dark
                                        @click="addCriteriaDetails(item.AwardTypeId)"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="font-size-h6 px-3 white--text"
                                      >
                                        Bid
                                      </v-btn>
                                    </template>
                                    <span> Bid </span>
                                  </v-tooltip>

                                  <v-tooltip
                                    bottom
                                    v-else-if="
                                      tableOptions1.CreateButtonFlag &&
                                      item.AwardStatus == 1 &&
                                      (!StartDateFlag || !EndDateFlag)
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="#BDBDBD"
                                        dark
                                        @click="deadlineAlert"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="font-size-h6 px-3 white--text"
                                      >
                                        Bid
                                      </v-btn>
                                    </template>
                                    <span> Bid </span>
                                  </v-tooltip>

                                  <v-tooltip
                                    bottom
                                    v-if="
                                      item.EditButtonFlag &&
                                      item.AwardId != 0 &&
                                      item.AwardStatus == 4 &&
                                      StartDateFlag &&
                                      EndDateFlag
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="warning"
                                        dark
                                        @click="editAlert(item, e)"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="font-size-h6 px-3 white--text"
                                      >
                                        Edit
                                      </v-btn>
                                    </template>
                                    <span> Edit </span>
                                  </v-tooltip>

                                  <v-tooltip
                                    bottom
                                    v-else-if="
                                      item.EditButtonFlag &&
                                      item.AwardId != 0 &&
                                      item.AwardStatus == 4 &&
                                      (!StartDateFlag || !EndDateFlag)
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="#BDBDBD"
                                        dark
                                        @click="deadlineAlert"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="font-size-h6 px-3 white--text"
                                      >
                                        Edit
                                      </v-btn>
                                    </template>
                                    <span> Edit </span>
                                  </v-tooltip>
                                </td>
                                <td>
                                  <h6 class="text-dark-95">
                                    {{ item.AwardTypeTxt }}
                                  </h6>
                                </td>
                                <td>
                                  <v-tooltip
                                    bottom
                                    v-if="item.PreviewButtonFlag && item.AwardId != 0"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        icon
                                        size="25px"
                                        color="primary"
                                        @click="previewAlert(item, e)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-file-search
                                      </v-icon>
                                    </template>
                                    <span> Preview </span>
                                  </v-tooltip>
                                  <!-- <v-tooltip
                                    bottom
                                    v-if="
                                      item.EditButtonFlag &&
                                      item.AwardId != 0 &&
                                      item.AwardStatus == 4
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        icon
                                        size="25px"
                                        color="warning"
                                        @click="editAlert(item, e)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-file-document-edit
                                      </v-icon>
                                    </template>
                                    <span> Edit </span>
                                  </v-tooltip> -->
                                  <v-tooltip
                                    bottom
                                    v-if="
                                      item.DeleteButtonFlag &&
                                      item.AwardId != 0 &&
                                      RoleId == 2
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        icon
                                        size="25px"
                                        color="#ff0000"
                                        @click="deleteAlert(item, e)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </template>
                                    <span> Delete </span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </template>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="openLomDetailsPrompt" persistent max-width="80%">
      <lom-details
        :openLomDetailsPrompt="openLomDetailsPrompt"
        :LomCode="LomCode"
        @hideLomDetailsPrompt="hideLomDetailsPrompt($event)"
        v-if="openLomDetailsPrompt"
      ></lom-details>
    </v-dialog>

    <v-dialog v-model="previewRecordPrompt" persistent max-width="80%">
      <nat-con-individual-award-preview
        :previewRecordPrompt="previewRecordPrompt"
        :recordData="recordData"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt($event)"
        v-if="previewRecordPrompt"
      ></nat-con-individual-award-preview>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import NatConIndividualAwardPreview from "@/view/pages/erp/jci-award/natcon/individual/NatConIndividualAwardPreview.vue";
import LomDetails from "@/view/pages/erp/jci-award/natcon/common/LomDetails.vue";

export default {
  mixins: [common],
  components: {
    NatConIndividualAwardPreview,
    LomDetails,
  },
  data() {
    return {
      StartupLoadingFlag: false,
      LoadingFlag: false,
      SubmitFlag: false,
      CardLoadingFlag: false,
      OverlayLoadingFlag: false,

      ProgessStart: 0,
      uploadPercentage: 0,

      NatconIndividualAwardStartDate: "",
      NatconIndividualAwardEndDate: "",
      TotalNatconIndividualAward: 0,
      TotalApplied: 0,
      TotalPartiallySaved: 0,

      StartDateFlag: false,
      EndDateFlag: false,

      tab: "",

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],

      LomCodeRules: [(v) => !!v || "Lom is required"],
      LomCode: 0,
      LomCodeOptions: [],

      IndividualAward: [],

      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],

      recordData: {},
      selectedData: {},

      openLomDetailsPrompt: false,
      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      ActiveStatus: "",

      valid: true,

      RoleId: 0,
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        var zone_name = this.$session.get("ZoneName");
        zone_name = zone_name == (null || undefined) ? 0 : zone_name;

        this.PageTitle = tr.PageTitle + " - " + zone_name;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");
      this.IndividualAward = [];
      this.getLomCodeOptions();
    },
    LomCode: function () {
      console.log("watch LomCode");
      this.getAwardTypeDetails();
      this.getTotalNatConIndividualAwardsDetails();
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "national_individual_award",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      if (this.RoleId == 2) {
        this.getZoneCodeOptions();
      }
      this.getAwardTypeDetails();
      this.getTotalNatConIndividualAwardsDetails();
    },
    getTotalNatConIndividualAwardsDetails() {
      console.log("getTotalNatConIndividualAwardsDetails called");

      var validate1 = true;
      console.log({ validate1 });

      if (validate1) {
        this.CardLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award-type/total-awards";

        var AwardCategory = 5;
        var AwardGroup = 1;

        if (this.RoleId == 2) {
          // For Admin
          var upload = {
            UserInterface: 1,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
            Lom: this.LomCode,
          };
        } else {
          // For Others
          var upload = {
            UserInterface: 1,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
          };
        }

        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.CardLoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              console.log("records = " + JSON.stringify(records));
              thisIns.rows = records;

              thisIns.NatconIndividualAwardStartDate = records.StartDateTxt;
              thisIns.NatconIndividualAwardEndDate = records.EndDateTxt;
              thisIns.TotalNatconIndividualAward = records.TotalAward;
              thisIns.TotalApplied = records.TotalApplied;
              thisIns.TotalPartiallySaved = records.TotalPartiallySaved;
              thisIns.StartDateFlag = records.StartDateFlag;
              thisIns.EndDateFlag = records.EndDateFlag;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            thisIns.CardLoadingFlag = false;
            console.log("error=" + error);
            thisIns.toast("error", error);
          });
      } else {
        var message = "";
        console.log("error=" + message);
      }
    },
    deadlineAlert() {
      console.log("deadlineAlert called");
      var htmlTxt = "";
      htmlTxt += "<h3> Natcon Lom Awards Bidding will be";
      htmlTxt += "<br/> Start <b> " + this.NatconIndividualAwardStartDate + " </b> and";
      htmlTxt += "<br/> End <b> " + this.NatconIndividualAwardEndDate + " </b> </h3>";

      Swal.fire({
        title: "Sorry !",
        text: "",
        icon: "warning",
        html: htmlTxt,
      });
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var ZoneCode = this.ZoneCode;
      console.log({ ZoneCode });
      if (ZoneCode != "") {
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        message += "Zone should not be empty. ";
      }
    },
    addCriteriaDetails(id) {
      console.log("addCriteriaDetails called");
      console.log({ id });
      if (id > 0) {
        this.$router.push("/national/individual-award/create?id=" + id);
      }
    },
    reset() {
      console.log("reset called");
      this.$refs.form.reset();
      this.AllMempership = [];
      this.AllTitles = [];
      this.AllProgramme = [];
      this.AllIncome = [];
      this.AllExpenses = [];
      this.AllProject = [];
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form.resetValidation();
    },
    getAwardTypeDetails() {
      console.log("getAwardTypeDetails called");

      var validate1 = true;
      console.log({ validate1 });

      var AwardCategory = 5;
      var AwardGroup = 1;

      if (validate1) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award-type/lists";
        if (this.RoleId == 2) {
          var upload = {
            UserInterface: 6,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
            Lom: this.LomCode,
          };
        } else {
          var upload = {
            UserInterface: 6,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
          };
        }
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
        );

        this.IndividualAward = [];

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.StartupLoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            var options = records.TableOptions;
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.SubmitFlag = false;
              console.log({ records });
              //   thisIns.tableColumns1 = records.TableHeader;

              thisIns.IndividualAward = records.TableData;

              thisIns.toast("success", output);
            } else {
              thisIns.SubmitFlag = false;
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            thisIns.SubmitFlag = false;
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "";
        console.log("error=" + message);
      }
    },
    previewAlert(tr) {
      console.log("previewAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.recordData = tr;
        this.previewRecordPrompt = true;
      } else {
        var message = "Kindly select one record to preview";
        this.errorMessage(message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },

    hideLomDetailsPrompt() {
      console.log("hideLomDetailsPrompt called");
      this.openLomDetailsPrompt = false;
    },
    editAlert(tr) {
      console.log("editAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var id = tr.AwardId;
        console.log({ id });
        if (id > 0) {
          this.$router.push("/national/individual-award/edit?id=" + id);
        }
      } else {
        var message = "Kindly select one record to preview";
        this.errorMessage(message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.selectedData = tr;
        var id = tr.AwardId;
        console.log({ id });
        if (id > 0) {
          this.confirmAlert();
        }
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/>Award Group <b> " + tr.AwardGroupDisplayText + "</b>";
        htmlTxt += "<br/> Award Name <b> " + tr.AwardTypeTitle + "</b>";
        htmlTxt += "<br/>Award Status <b> " + tr.AwardStatusTxt + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";

        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRecord(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteRecord(tr) {
      console.log("deleteRecord called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });

      if (n1 > 0 && this.RoleId == 2) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award/destroy";

        var upload = {
          UserInterface: 1,
          Award: tr.AwardId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.getAwardTypeDetails();
              thisIns.sweetAlert("success", output, false);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    console.log("beforeMount called");

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? "" : RoleId;
    console.log({ RoleId });
    this.RoleId = RoleId;

    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#natcon-individual-awards-list {
  #card-text {
    text-align: center;
    color: white;
  }
  #card-text h5 {
    font-size: 34px;
  }
  #card-text h2 {
    font-size: 34px;
    font-weight: 900;
  }
  #card-text h1 {
    font-size: 44px;
    font-weight: 900;
  }
}
</style>
