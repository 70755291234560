<template>
  <div id="lom-award-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0" v-if="row != ''">
          <h3 class="text-dark text-center">Award Detials</h3>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="4">
              <h6 class="blue--text">Award Status</h6>
              <h6>
                <v-chip
                  :color="row.AwardStatusColor"
                  draggable
                  class="font-size-h5 white--text px-4 py-3"
                >
                  {{ row.AwardStatusTxt }}
                </v-chip>
                <!-- {{ row.AwardStatusTxt }} -->
              </h6>
            </v-col>
            <v-col cols="12" sm="12" lg="4">
              <h6 class="blue--text">Award Register Number</h6>
              <h6>
                {{ row.AwardRegisterNumber }}
              </h6>
            </v-col>
            <v-col cols="12" sm="12" lg="4">
              <h6 class="blue--text">Award Type</h6>
              <h6>
                {{ row.AwardTypeTitleTxt }}
              </h6>
            </v-col>
            <v-col cols="12" sm="12" lg="4">
              <h6 class="blue--text">Award Category</h6>
              <h6>
                {{ row.AwardCategoryName }}
              </h6>
            </v-col>
          </v-row>
          <!-- <br /> -->
          <v-divider></v-divider>
          <!-- <br /> -->
          <h3 class="text-dark text-center">Award Nominee Member</h3>
          <div align="center" class="mt-2 mb-2">
            <!-- <h6 class="blue--text">Member Photo</h6> -->
            <v-img
              :src="row.MemberImage"
              :alt="row.MemberFullName"
              max-width="100"
              max-height="100"
              v-if="row.MemberImage != ''"
            >
            </v-img>
          </div>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="3" md="4">
              <h6 class="blue--text">MembershipId</h6>
              <h6 class="text-dark">{{ row.MembershipId }}</h6>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="4">
              <h6 class="blue--text">Name</h6>
              <h6 class="text-dark">{{ row.MemberFullName }}</h6>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="4">
              <h6 class="blue--text">Email Id</h6>
              <h6 class="text-dark">{{ row.EmailId }}</h6>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="4">
              <h6 class="blue--text">Mobile No</h6>
              <h6 class="text-dark">{{ row.MobileNo }}</h6>
            </v-col>
          </v-row>
          <!-- <br /> -->
          <v-divider></v-divider>
          <!-- <br /> -->
          <h3 class="text-dark text-center">Criteria Details</h3>
          <v-row>
            <v-col
              cols="12"
              md="12"
              lg="12"
              v-for="(item, index) in row.AwardAnswersArray"
              :key="index"
            >
              <template v-if="item.CriteriaTitleFlag">
                <!-- <br /> -->
                <h4 class="text-dark mt-3">
                  <b>{{ item.CriteriaTitle }}</b>
                  <small> (score - {{ item.CriteriaScore }}) </small>
                </h4>
              </template>
              <h6 class="blue--text">{{ item.QuestionsText }}</h6>
              <h6 class="blue--text">
                Ans:- <span class="text-dark">{{ item.AnswerText }}</span>
              </h6>
              <br />
              <template v-if="item.EventDetails.length > 0">
                <h6 class="text-dark">
                  Award Attachments (max - {{ item.QuestionsAttachmentMax }})
                </h6>
              </template>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  lg="3"
                  v-for="(data, k) in item.EventDetails"
                  :key="k"
                >
                  <v-card>
                    <v-card-text>
                      <h6 align="center">
                        <viewer rebuild :images="data.EventImagesArray">
                          <img
                            style="padding: 5px"
                            width="80"
                            height="80"
                            :src="src"
                            :key="src"
                            v-for="src in data.EventImagesArray"
                          />
                        </viewer>
                      </h6>
                      <h6>
                        Event Date -
                        <span class="text-dark">{{ data.EventDate }}</span>
                      </h6>
                      <!-- <h6>
                          Area-
                          <span class="text-dark">{{
                            data.AnswerPortfolioName
                          }}</span>
                        </h6> -->
                      <h6>
                        Event Name -
                        <span class="text-dark">{{ data.EventName }}</span>
                      </h6>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <template v-if="row.AnswerAttachmentOfficers.length > 0">
            <v-divider />
            <h3 class="text-center text-dark mt-5">Other Attachments</h3>
            <v-row wrap class="mt-1">
              <v-col
                cols="12"
                sm="12"
                lg="3"
                v-for="(data, k) in row.AnswerAttachmentOfficers"
                :key="k"
              >
                <v-card>
                  <v-card-text>
                    <h6 align="center">
                      <viewer rebuild :images="data.EventImagesArray">
                        <img
                          style="padding: 5px"
                          width="80"
                          height="80"
                          :src="src"
                          :key="src"
                          v-for="src in data.EventImagesArray"
                        />
                      </viewer>
                    </h6>
                    <h6>
                      Event Date -
                      <span class="text-dark">{{ data.EventDate }}</span>
                    </h6>
                    <h6>
                      Event Name -
                      <span class="text-dark">{{ data.EventName }}</span>
                    </h6>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>

          <div v-if="row.AwardVideoPath != null">
            <v-divider></v-divider>
            <h3 class="text-center text-dark">Video Details</h3>
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12">
                <h5 class="text-danger">* Notes for Video File:</h5>
                <h6 class="text-dark">
                  The video should be <br />
                  &nbsp; &nbsp; 1. In MP4 or AVI or MOV file format <br />
                  &nbsp; &nbsp; 2. Must be less than 5MB in file size
                </h6>
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="12">
                <!-- <h6 class="mb-4">Uploaded Video will appear here</h6> -->
                <!-- <v-btn
                  rounded
                  medium
                  color="purple"
                  class="font-size-h6 px-6 mr-3 white--text"
                  :href="row.AwardVideoPath"
                  target="_blank"
                >
                  Download
                </v-btn> -->
                <!-- <h6>{{ row.AwardVideoPath }}</h6> -->
                <video
                  :src="row.AwardVideoPath"
                  width="400px"
                  height="300px"
                  controls
                  class="mt-4"
                ></video>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <br />
      <br />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closePrompt"
          elevation="30"
          shaped
          tile
          medium
          color="#a52a2a"
          class="font-size-h6 px-6 mr-3 white--text"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordPrompt: {
      required: true,
      Type: Boolean,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      search: "",
      StartupLoadingFlag: false,
      LoadingFlag: false,
      SubmitFlag: false,

      ProgessStart: 0,
      uploadPercentage: 0,

      tab: "",

      row: [],

      addRecordPrompt: false,
      editRecordPrompt: false,

      ActiveStatus: "",

      valid: true,

      RoleId: "",
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        var zone_name = this.$session.get("ZoneName");
        zone_name = zone_name == (null || undefined) ? 0 : zone_name;

        this.PageTitle = tr.PageTitle + " - " + zone_name;

        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "national_individual_award",
        Action: "preview",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");

      var recordData = this.recordData;
      console.log({ recordData });
      this.getTableRecords();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var AwardId = this.recordData.AwardId;
      console.log({ AwardId });

      if (AwardId > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award/show";
        var upload = {
          UserInterface: 1,
          Award: AwardId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.RecordFlag = flag;

            if (flag == 1) {
              console.log("records=" + JSON.stringify(records));

              thisIns.row = records;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Award details not found.";
        console.log("error=" + message);
      }
    },
  },
  beforeMount() {
    console.log("beforeMount called");

    // var RoleId = this.$session.get("RoleId");
    // RoleId = RoleId == (null || undefined) ? "" : RoleId;
    // console.log({ RoleId });
    // this.RoleId = RoleId;

    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#lom-award-create {
  .notes-text {
    font-size: 18px;
    color: red;
  }
}
</style>
